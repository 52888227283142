<template>
    <vue-splash
        :show="true"
        :logo="logo"
        title=""
        color="#004cff"
        :size="350"
        :fixed="true"
    />
</template>

<script>
import YourLogo from "../assets/alqamer.png";

export default {
    name: "YourMagnificentVueComponent",
    computed: {
        logo() {
            return YourLogo;
        }
    }
};
</script>
