import axios from "axios";

export default {
    namespaced: true,
    state:  ({
        currencies: [],
        dataLoading: false,
    }),
    mutations: {
        setCurrencies(state, currencies) {
            state.currencies = currencies;
        },
        setDataLoading(state, dataLoading) {
            state.dataLoading = dataLoading;
        }
    },
    actions: {
        getCurrencies({ commit }, id) {
            commit("setDataLoading", true);
            axios.get("Currencies/Get?" + id).then(response => {
                commit("setCurrencies", response.data.data);
            }).finally(() => {
                commit("setDataLoading", false);
             });
        }
    },
    getters: {
        isLoading(state) {
            return state.dataLoading;
        },
        getCurrencies(state) {
            return state.currencies;
        }
    },
}