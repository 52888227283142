<template>
    <v-navigation-drawer
        v-model="drawer"
        id="core-navigation-drawer"
        :right="$vuetify.rtl"
        app
        width="280"
        v-bind="$attrs"
    >
        <v-list dense nav class="ma-0 pa-0">
            <v-list-item class="ma-0 pa-0">
                <v-btn
                    style="padding: 31px !important"
                    class="ma-0"
                    color="primary"
                    min-width="100%"
                    min-height="100%"
                    tile
                    depressed
                    to="/"
                >
                    <span dir="auto" class="pt-1 mx-1"
                        >{{ $t("alqamer") }}
                    </span>
                </v-btn>
            </v-list-item>
        </v-list>

        <v-list class="mb-10" nav>
            <template v-for="(item, i) in myItems.filter(f => f.visible)">
                <v-list-group
                    exact
                    :key="i"
                    :prepend-icon="item.icon"
                    v-if="item.children"
                    v-model="item.active"
                >
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ $t(item.title) }}</v-list-item-title
                            >
                        </v-list-item-content>
                    </template>

                    <v-list-item
                        active-class="primary white--text"
                        v-for="(subItem, ind) in item.children.filter(
                            f => f.visible
                        )"
                        :key="ind"
                        :to="subItem.to"
                    >
                        <v-list-item-icon> </v-list-item-icon>
                        <v-list-item-title class="mx-n2">
                            <v-icon>mdi-circle-small</v-icon>
                            {{ $t(subItem.title) }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list-group>

                <v-list-item
                    v-else
                    :to="item.to"
                    :key="item"
                    active-class="primary"
                >
                    <v-list-item-icon>
                        <v-icon>
                            {{ item.icon }}
                        </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                </v-list-item>
            </template>
        </v-list>
    </v-navigation-drawer>
</template>
<script>
import { mapState } from "vuex";
export default {
    name: "DashboardCoreDrawer",
    props: {
        expandOnHover: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        drawer1: true,
        items: [
            {
                icon: "mdi-view-dashboard-outline",
                title: "Dashboard",
                group: "dashboard",
                children: [
                    {
                        title: "groups.groups",
                        to: "/groups",
                        role: "6"
                    },
                    {
                        icon: "mdi-account",
                        title: "users.users",
                        to: "/users",
                        role: "11"
                    },
                    {
                        title: "settings.settings",
                        icon: "mdi-clipboard-outline",
                        to: "/settings",
                        role: "71"
                    },
                    {
                        title: "branches.branches",
                        icon: "mdi-clipboard-outline",
                        to: "/branch",
                        role: "1"
                    }
                ]
            },
            {
                icon: "mdi-account-cash-outline",
                title: "accountTree.accounts",
                group: "accounts",
                children: [
                    {
                        title: "accountTree.accountTree",
                        icon: "mdi-clipboard-outline",
                        to: "/accountTree",
                        role: "16"
                    },
                    {
                        title: "customer.customers",
                        icon: "mdi-clipboard-outline",
                        to: "/customers",
                        role: "22"
                    },
                    {
                        title: "currency.currencies",
                        icon: "mdi-clipboard-outline",
                        to: "/currencies",
                        role: "19"
                    },
                    {
                        title: "voucher.vouchers",
                        icon: "mdi-clipboard-outline",
                        to: "/vouchers",
                        role: "25"
                    },
                    {
                        title: "voucher.receipt",
                        icon: "mdi-clipboard-outline",
                        to: "/receiptvoucher/2",
                        role: "26"
                    },
                    {
                        title: "voucher.payment",
                        icon: "mdi-clipboard-outline",
                        to: "/paymentvoucher/3",
                        role: "27"
                    }
                ]
            },
            {
                icon: "mdi-archive-outline",
                title: "stock.stocks",
                group: "stocks",
                role: "55",
                children: [
                    {
                        title: "stock.stocks",
                        icon: "mdi-clipboard-outline",
                        to: "/stocks",
                        role: "55"
                    },
                    {
                        title: "item.items",
                        icon: "mdi-clipboard-outline",
                        to: "/items",
                        role: "58"
                    }
                ]
            },
            {
                icon: "mdi-cash-multiple",
                title: "bill.bills",
                group: "Bills",
                role: "31",
                children: [
                    {
                        title: "bill.bills",
                        icon: "mdi-clipboard-outline",
                        to: "/bills",
                        role: "31"
                    },
                    {
                        title: "bill.purchaseBill",
                        icon: "mdi-clipboard-outline",
                        to: "/purchaseBill",
                        role: "32"
                    },
                    {
                        title: "bill.saleBill",
                        icon: "mdi-clipboard-outline",
                        to: "/saleBill",
                        role: "34"
                    },
                    {
                        title: "bill.saleReturnBill",
                        icon: "mdi-clipboard-outline",
                        to: "/returnSaleBill",
                        role: "35"
                    },
                    {
                        title: "bill.purchaseReturnBill",
                        icon: "mdi-clipboard-outline",
                        to: "/returnPurchaseBill",
                        role: "33"
                    },
                    {
                        title: "bill.installmentsSaleBill",
                        icon: "mdi-clipboard-outline",
                        to: "/installmentsSaleBill",
                        role: "36"
                    }
                ]
            },
            {
                icon: "mdi-school-outline",
                title: "school.schools",
                group: "schools",
                role: "43",
                children: [
                    {
                        title: "school.students",
                        icon: "mdi-clipboard-outline",
                        to: "/schools/students",
                        role: "43"
                    },
                    {
                        title: "school.semesters",
                        icon: "mdi-clipboard-outline",
                        to: "/schools/semesters",
                        role: "46"
                    },
                    {
                        title: "school.schoolStages",
                        icon: "mdi-clipboard-outline",
                        to: "/schools/schoolStages",
                        role: "49"
                    },
                    {
                        title: "school.classes",
                        icon: "mdi-clipboard-outline",
                        to: "/schools/classes",
                        role: "52"
                    },
                    {
                        title: "itemTemplate.itemTemplates",
                        icon: "mdi-clipboard-outline",
                        to: "/PaymentPlans",
                        role: "87"
                    },
                    {
                        title: "school.studentUpgrading",
                        icon: "mdi-clipboard-outline",
                        to: "/StudentUpgrading",
                        role: "91"
                    },
                    {
                        title: "fees.fees",
                        icon: "mdi-clipboard-outline",
                        to: "/fees",
                        role: "58"
                    }
                ]
            },

            {
                icon: "mdi-chart-box-outline",
                title: "reports.reports",
                group: "reports",
                role: "70",
                children: [
                    {
                        title: "reports.billsReport",
                        icon: "mdi-clipboard-outline",
                        to: "/reports/billsReport",
                        role: "64"
                    },
                    {
                        title: "reports.billsProfitReport",
                        icon: "mdi-clipboard-outline",
                        to: "/reports/billsProfitReport",
                        role: "63"
                    },
                    {
                        title: "reports.itemsProfitReport",
                        icon: "mdi-clipboard-outline",
                        to: "/reports/itemsProfitReport",
                        role: "66"
                    },
                    {
                        title: "reports.itemsReport",
                        icon: "mdi-clipboard-outline",
                        to: "/reports/itemsReport",
                        role: "67"
                    },
                    {
                        title: "reports.itemsSalesReport",
                        icon: "mdi-clipboard-outline",
                        to: "/reports/itemsSalesReport",
                        role: "68"
                    },
                    {
                        title: "reports.accountsMovementReport",
                        icon: "mdi-clipboard-outline",
                        to: "/reports/accountsMovementReport",
                        role: "62"
                    },
                    {
                        title: "reports.accountsBalancesReport",
                        icon: "mdi-clipboard-outline",
                        to: "/reports/accountsBalancesReport",
                        role: "61"
                    },
                    {
                        title: "reports.customerAccountStatementReport",
                        icon: "mdi-clipboard-outline",
                        to: "/reports/customerAccountStatementReport",
                        role: "65"
                    },
                    {
                        title: "reports.paymentsReport",
                        icon: "mdi-clipboard-outline",
                        to: "/reports/paymentsReport",
                        role: "69"
                    },
                    {
                        title: "reports.latePaymentsReport",
                        icon: "mdi-clipboard-outline",
                        to: "/reports/latePaymentsReport",
                        role: "86"
                    }
                ]
            }
        ]
    }),
    watch: {
        drawer1() {
            this.drawer1 = true;
        }
    },
    created() {
        var childs = this.myItems.filter(item => {
            return (
                item.children &&
                item.children.some(it => {
                    return it.to == this.$route.path;
                })
            );
        });
        if (childs && childs.length > 0) {
            childs[0].active = true;
        }
    },
    computed: {
        ...mapState(["barColor", "barImage"]),
        drawer: {
            get() {
                return this.$store.state.drawer;
            },
            set(val) {
                this.$store.commit("SET_DRAWER", val);
            }
        },

        computedItems() {
            var indexes = [];
            var items = JSON.parse(JSON.stringify(this.items));

            for (var i = 0; i < items.length; i++) {
                var item = items[i];
                if (item.role) {
                    if (!this.isInRole(item.role)) {
                        items[i] = null;
                    }
                }
                var childRoles = [];
                var child = item.children;
                if (child) {
                    for (var j = 0; j < child.length; j++) {
                        var subItem = child[j];

                        if (subItem.role) {
                            if (!this.isInRole(subItem.role)) {
                                child[j] = null;
                            } else {
                                childRoles.push(subItem.role);
                            }
                        }
                    }
                    item.children = child.filter(f => {
                        return f != null && f != "null";
                    });
                }

                if (childRoles.length == 0 && !item.role) {
                    indexes.push(i);
                }
            }

            for (var m = 0; m < indexes.length; m++) {
                items.splice(indexes[m], 1);
            }

            return items.filter(f => {
                return f != null && f != "null";
            });
        },
        profile() {
            return {
                avatar: true
            };
        },

        myItems() {
            var list = [
                {
                    icon: "mdi-view-dashboard-outline",
                    title: "Dashboard",
                    group: "dashboard",
                    visible:
                        this.$store.getters.isInRole(6) ||
                        this.$store.getters.isInRole(11) ||
                        this.$store.getters.isInRole(71) ||
                        this.$store.getters.isInRole(72) ||
                        this.$store.getters.isInRole(73) ||
                        this.$store.getters.isInRole(75) ||
                        this.$store.getters.isInRole(1) ||
                        this.$store.getters.isInRole(0),
                    children: [
                        {
                            title: "groups.groups",
                            to: "/groups",
                            role: "6",
                            visible: this.$store.getters.isInRole(6)
                        },
                        {
                            icon: "mdi-account",
                            title: "users.users",
                            to: "/users",
                            role: "11",
                            visible: this.$store.getters.isInRole(11)
                        },
                        {
                            title: "settings.settings",
                            icon: "mdi-clipboard-outline",
                            to: "/settings",
                            role: "71",
                            visible:
                                this.$store.getters.isInRole(71) ||
                                this.$store.getters.isInRole(72) ||
                                this.$store.getters.isInRole(73) ||
                                this.$store.getters.isInRole(75)
                        },
                        {
                            title: "systemLog.systemLog",
                            icon: "mdi-clipboard-outline",
                            to: "/systemlog",
                            role: "0",
                            visible: this.$store.getters.isInRole(0)
                        },
                        {
                            title: "branches.branches",
                            icon: "mdi-clipboard-outline",
                            to: "/branch",
                            role: "1",
                            visible: this.$store.getters.isInRole(1)
                        }
                    ]
                },
                {
                    icon: "mdi-account-cash-outline",
                    title: "accountTree.accounts",
                    group: "accounts",
                    visible:
                        this.$store.getters.isInRole(16) ||
                        this.$store.getters.isInRole(22) ||
                        this.$store.getters.isInRole(19) ||
                        this.$store.getters.isInRole(26) ||
                        this.$store.getters.isInRole(27) ||
                        this.$store.getters.isInRole(92) ||
                        this.$store.getters.isInRole(25),
                    children: [
                        {
                            title: "accountTree.accountTree",
                            icon: "mdi-clipboard-outline",
                            to: "/accountTree",
                            role: "16",
                            visible: this.$store.getters.isInRole(16)
                        },
                        {
                            title: "fiscalYear.fiscalYears",
                            icon: "mdi-clipboard-outline",
                            to: "/fiscalYears",
                            role: "92",
                            visible: this.$store.getters.isInRole(92)
                        },
                        {
                            title: "customer.customers",
                            icon: "mdi-clipboard-outline",
                            to: "/customers",
                            role: "22",
                            visible: this.$store.getters.isInRole(22)
                        },
                        {
                            title: "currency.currencies",
                            icon: "mdi-clipboard-outline",
                            to: "/currencies",
                            role: "19",
                            visible: this.$store.getters.isInRole(19)
                        },
                        {
                            title: "voucher.vouchers",
                            icon: "mdi-clipboard-outline",
                            to: "/vouchers",
                            role: "25",
                            visible: this.$store.getters.isInRole(25)
                        },
                        {
                            title: "voucher.receipt",
                            icon: "mdi-clipboard-outline",
                            to: "/receiptvoucher/2",
                            role: "26",
                            visible: this.$store.getters.isInRole(26)
                        },
                        {
                            title: "voucher.payment",
                            icon: "mdi-clipboard-outline",
                            to: "/paymentvoucher/3",
                            role: "27",
                            visible: this.$store.getters.isInRole(27)
                        }
                    ]
                },
                {
                    icon: "mdi-archive-outline",
                    title: "stock.stocks",
                    group: "stocks",
                    visible:
                        this.$store.getters.isInRole(55) ||
                        (this.$store.getters.isInRole(58) &&
                            !this.$store.getters.isInRole(43)),
                    children: [
                        {
                            title: "stock.stocks",
                            icon: "mdi-clipboard-outline",
                            to: "/stocks",
                            role: "55",
                            visible: this.$store.getters.isInRole(55)
                        },
                        {
                            title: "item.items",
                            icon: "mdi-clipboard-outline",
                            to: "/items",
                            role: "58",
                            visible: this.$store.getters.isInRole(58)
                        }
                    ]
                },
                {
                    icon: "mdi-cash-multiple",
                    title: "bill.bills",
                    group: "Bills",
                    visible:
                        (this.$store.getters.isInRole(31) &&
                            !this.$store.getters.isInRole(43)) ||
                        this.$store.getters.isInRole(32) ||
                        this.$store.getters.isInRole(34) ||
                        this.$store.getters.isInRole(35) ||
                        this.$store.getters.isInRole(33) ||
                        this.$store.getters.isInRole(36),
                    children: [
                        {
                            title: "bill.bills",
                            icon: "mdi-clipboard-outline",
                            to: "/bills",
                            role: "31",
                            visible: this.$store.getters.isInRole(31)
                        },
                        {
                            title: "bill.purchaseBill",
                            icon: "mdi-clipboard-outline",
                            to: "/purchaseBill",
                            role: "32",
                            visible: this.$store.getters.isInRole(32)
                        },
                        {
                            title: "bill.saleBill",
                            icon: "mdi-clipboard-outline",
                            to: "/saleBill",
                            role: "34",
                            visible: this.$store.getters.isInRole(34)
                        },
                        {
                            title: "bill.saleReturnBill",
                            icon: "mdi-clipboard-outline",
                            to: "/returnSaleBill",
                            role: "35",
                            visible: this.$store.getters.isInRole(35)
                        },
                        {
                            title: "bill.purchaseReturnBill",
                            icon: "mdi-clipboard-outline",
                            to: "/returnPurchaseBill",
                            role: "33",
                            visible: this.$store.getters.isInRole(33)
                        },
                        {
                            title: "bill.installmentsSaleBill",
                            icon: "mdi-clipboard-outline",
                            to: "/installmentsSaleBill",
                            role: "36",
                            visible: this.$store.getters.isInRole(36)
                        }
                    ]
                },
                {
                    icon: "mdi-notebook-outline",
                    title: "itemTemplate.itemTemplates",
                    group: "Plans",
                    visible:
                        (this.$store.getters.isInRole(43) &&
                            this.$store.getters.isInRole(58)) ||
                        this.$store.getters.isInRole(87),
                    children: [
                        {
                            title: "fees.fees",
                            icon: "mdi-clipboard-outline",
                            to: "/fees",
                            role: "58",
                            visible:
                                this.$store.getters.isInRole(58) &&
                                this.$store.getters.isInRole(43)
                        },
                        {
                            title: "itemTemplate.itemTemplates",
                            icon: "mdi-clipboard-outline",
                            to: "/PaymentPlans",
                            role: "87",
                            visible: this.$store.getters.isInRole(87)
                        }
                    ]
                },
                {
                    icon: "mdi-school-outline",
                    title: "school.schools",
                    group: "schools",
                    visible:
                        this.$store.getters.isInRole(43) ||
                        this.$store.getters.isInRole(46) ||
                        this.$store.getters.isInRole(49) ||
                        this.$store.getters.isInRole(52) ||
                        this.$store.getters.isInRole(91),
                    children: [
                        {
                            title: "school.students",
                            icon: "mdi-clipboard-outline",
                            to: "/schools/students",
                            role: "43",
                            visible: this.$store.getters.isInRole(43)
                        },
                        {
                            title: "school.semesters",
                            icon: "mdi-clipboard-outline",
                            to: "/schools/semesters",
                            role: "46",
                            visible: this.$store.getters.isInRole(46)
                        },
                        {
                            title: "school.schoolStages",
                            icon: "mdi-clipboard-outline",
                            to: "/schools/schoolStages",
                            role: "49",
                            visible: this.$store.getters.isInRole(49)
                        },
                        {
                            title: "school.classes",
                            icon: "mdi-clipboard-outline",
                            to: "/schools/classes",
                            role: "52",
                            visible: this.$store.getters.isInRole(52)
                        },
                        {
                            title: "school.studentUpgrading",
                            icon: "mdi-clipboard-outline",
                            to: "/StudentUpgrading",
                            role: "91",
                            visible: this.$store.getters.isInRole(91)
                        }
                    ]
                },
                {
                    icon: "mdi-credit-card-outline",
                    title: "payments.payments",
                    group: "Payments",
                    visible: this.$store.getters.isInRole(96),
                    children: [
                        {
                            title: "payments.payments",
                            icon: "mdi-clipboard-outline",
                            to: "/payments",
                            role: "96",
                            visible: this.$store.getters.isInRole(96)
                        }
                    ]
                },
                {
                    icon: "mdi-chart-box-outline",
                    title: "reports.reports",
                    group: "reports",
                    visible:
                        this.$store.getters.isInRole(64) ||
                        this.$store.getters.isInRole(66) ||
                        this.$store.getters.isInRole(67) ||
                        this.$store.getters.isInRole(68) ||
                        this.$store.getters.isInRole(62) ||
                        this.$store.getters.isInRole(61) ||
                        this.$store.getters.isInRole(65) ||
                        this.$store.getters.isInRole(69) ||
                        this.$store.getters.isInRole(86) ||
                        this.$store.getters.isInRole(97) ||
                        this.$store.getters.isInRole(63),
                    children: [
                        {
                            title: "reports.billsReport",
                            icon: "mdi-clipboard-outline",
                            to: "/reports/billsReport",
                            role: "64",
                            visible: this.$store.getters.isInRole(64)
                        },
                        {
                            title: "reports.billsProfitReport",
                            icon: "mdi-clipboard-outline",
                            to: "/reports/billsProfitReport",
                            role: "63",
                            visible: this.$store.getters.isInRole(63)
                        },
                        {
                            title: "reports.itemsProfitReport",
                            icon: "mdi-clipboard-outline",
                            to: "/reports/itemsProfitReport",
                            role: "66",
                            visible: this.$store.getters.isInRole(66)
                        },
                        {
                            title: "reports.itemsReport",
                            icon: "mdi-clipboard-outline",
                            to: "/reports/itemsReport",
                            role: "67",
                            visible: this.$store.getters.isInRole(67)
                        },
                        {
                            title: "reports.itemsSalesReport",
                            icon: "mdi-clipboard-outline",
                            to: "/reports/itemsSalesReport",
                            role: "68",
                            visible: this.$store.getters.isInRole(68)
                        },
                        {
                            title: "reports.accountsMovementReport",
                            icon: "mdi-clipboard-outline",
                            to: "/reports/accountsMovementReport",
                            role: "62",
                            visible: this.$store.getters.isInRole(62)
                        },
                        {
                            title: "reports.accountsBalancesReport",
                            icon: "mdi-clipboard-outline",
                            to: "/reports/accountsBalancesReport",
                            role: "61",
                            visible: this.$store.getters.isInRole(61)
                        },
                        {
                            title: "reports.customerAccountStatementReport",
                            icon: "mdi-clipboard-outline",
                            to: "/reports/customerAccountStatementReport",
                            role: "65",
                            visible: this.$store.getters.isInRole(65)
                        },
                        {
                            title: "reports.paymentsReport",
                            icon: "mdi-clipboard-outline",
                            to: "/reports/paymentsReport",
                            role: "69",
                            visible: this.$store.getters.isInRole(69)
                        },
                        {
                            title: "reports.latePaymentsReport",
                            icon: "mdi-clipboard-outline",
                            to: "/reports/latePaymentsReport",
                            role: "86",
                            visible: this.$store.getters.isInRole(86)
                        },
                        {
                            title: "reports.studentsReport",
                            icon: "mdi-clipboard-outline",
                            to: "/reports/studentsReport",
                            role: "43",
                            visible: this.$store.getters.isInRole(97)
                        }
                    ]
                }
            ];
            return list;
        }
    },
    methods: {
        mapItem(item) {
            return {
                title: item.title,
                icon: item.icon,
                group: item.group
            };
        }
    }
};
</script>
<style>
.v-navigation-drawer__content {
    padding-bottom: 100px !important;
}
</style>
<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24



    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
