import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
import axios from "axios";
import jwt_decode from "jwt-decode";
import currenciesStore from "./modules/currenciesStore";

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
      currenciesStore: currenciesStore  
    },
    state: {
        barColor: "rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2)",
        drawer: localStorage.getItem("drawer") == "true",
        drawerMessage: null,
        idToken: localStorage.getItem("token"),
        userId: localStorage.getItem("userId"),
        fullname: localStorage.getItem("fullname"),
        expiration: localStorage.getItem("expiration"),
        isSuperAdmin : localStorage.getItem("isSuperAdmin"),
        allNotifications: 0,
        UploaderUrl: "",
        overlay: false
    },
    mutations: {
        SET_BAR_IMAGE(state, payload) {
            state.barImage = payload;
        },
        SET_DRAWER(state, payload) {
            state.drawer = payload;
            localStorage.setItem("drawer", payload);
        },
        SET_DRAWER_Message(state, payload) {
            state.drawerMessage = payload;
        },
        SET_SCRIM(state, payload) {
            state.barColor = payload;
        },
        SET_UPLOADER(state, payload) {
            state.UploaderUrl = payload;
        },
        authUser(state, userData) {
            state.idToken = userData.token;
            state.userId = userData.userId;
            state.fullname = userData.fullname;
            state.expiration = userData.expiration;
            state.roles = userData.roles;
            state.isSuperAdmin = userData.isSuperAdmin;
        },
        clearAuth(state) {
            state.idToken = null;
            state.userId = null;
            state.fullname = null;
            state.expiration = null;
            state.roles = null;
            state.isSuperAdmin = null;
        },
        overlay(state, overlay) {
            state.overlay = overlay;
        }
    },
    getters: {
        isLoading(state) {
            return state.overlay;
        },
        isInRole: (state) => (role) => {
            var is = jwt_decode(state.idToken).role.some((s) => s == role);
            return is;
        },
    },
    actions: {
        login({ commit }, userData) {
            localStorage.setItem("token", userData.accessToken);
            localStorage.setItem("userId", userData.id);
            localStorage.setItem("fullname", userData.userName);
            localStorage.setItem("expiration", userData.expiration);
            //localStorage.setItem("roles", userData.roles);
            localStorage.setItem("isSuperAdmin", userData.isSuperAdmin);
            //var jsonString = localStorage.getItem("userD");
            // var retrievedObject = JSON.parse(jsonString);
            commit("authUser", {
                token: userData.accessToken,
                userId: userData.id,
                fullname: userData.userName,
                expiration: userData.expiration,
                roles: userData.roles,
                isSuperAdmin: userData.isSuperAdmin
            });
            axios.defaults.headers.get["Authorization"] =
                "Bearer " + localStorage.getItem("token");
            router.push({
                name: "Dashboard"
            });
        },
        overlay({ commit }, overlay) {
            commit("overlay", overlay);
        },
        logout({ commit }) {
            commit("clearAuth");
            //localStorage.clear();

            if (this.$vuetify) {
                localStorage.setItem("dark", this.$vuetify.theme.dark);
                this.$vuetify.rtl =localStorage.getItem("lang") == "ar" ? true : false;
            }
            if (this.$i18n) {
                this.$i18n.locale = this.$i18n.locale == "ar" ? "ar" : "en";
                localStorage.setItem("lang", this.$i18n.locale);
            }
            localStorage.setItem("token", null);
            localStorage.setItem("userId", null);
            localStorage.setItem("fullname", null);
            localStorage.setItem("expiration", null);
            localStorage.setItem("roles", null);
            localStorage.setItem("isSuperAdmin", null);
            axios.defaults.headers.get["Authorization"] = null;
        }
    }
});
