<template>
    <v-app-bar id="app-bar" absolute app flat height="62">
        <v-btn class="mx-3" elevation="1" fab small @click="setDrawer(!drawer)">
            <v-icon v-if="value"> mdi-view-quilt </v-icon>

            <v-icon v-else> mdi-dots-vertical </v-icon>
        </v-btn>

        <!-- <v-toolbar-title class="hidden-sm-and-down font-weight-light">
      {{
        $route.name == "vouchers"
          ? $route.params.type == "1"
            ? $t("vouchers.receiptVouchers")
            : $t("vouchers.paymentVouchers")
          : $t($route.name)
      }}
    </v-toolbar-title> -->

        <v-spacer />

        <!-- <v-text-field color="secondary" hide-details style="max-width: 165px">
      <template v-if="$vuetify.breakpoint.mdAndUp" v-slot:append-outer>
        <v-btn class="mt-n2" elevation="1" fab small>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>
    </v-text-field> -->

        <div class="mx-2" />

        <dashboard-core-settings />

        <!-- <v-btn class="ml-1" min-width="0" color="grey lighten-1" text to="/">
      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn> -->

        <v-menu
            bottom
            left
            offset-y
            origin="top right"
            transition="scale-transition"
        >
            <template v-slot:activator="{ attrs, on }">
                <v-btn
                    class="ml-1"
                    min-width="0"
                    color="grey lighten-1"
                    text
                    v-bind="attrs"
                    v-on="on"
                >
                    {{ $t("hello") + "  " + userName }}

                    <!-- <v-badge color="red" overlap bordered>
            <template v-slot:badge>
              <span>5</span>
            </template>

            <v-icon>mdi-bell</v-icon>
          </v-badge> -->
                </v-btn>
            </template>

            <v-list :tile="false" nav>
                <div>
                    <v-list-item>
                        <v-list-item-title>
                            <v-btn text to="/changePassword">
                                {{ $t("users.changePassword") }}
                            </v-btn>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-title>
                            <v-btn text to="/auth">
                                {{ $t("appBar.logout") }}
                            </v-btn>
                        </v-list-item-title>
                    </v-list-item>
                </div>
            </v-list>
        </v-menu>

        <!-- <v-btn class="ml-2" min-width="0" text to="/pages/user">
      <v-icon>mdi-account</v-icon>
    </v-btn> -->
    </v-app-bar>
</template>

<script>
// Components
// import { VHover, VListItem } from "vuetify/lib";

import DashboardCoreSettings from "../components/Settings";
// Utilities
import { mapState, mapMutations } from "vuex";
export default {
    name: "DashboardCoreAppBar",

    components: {
        DashboardCoreSettings
        // AppBarItem: {
        //   render(h) {
        //     return h(VHover, {
        //       scopedSlots: {
        //         default: ({ hover }) => {
        //           return h(
        //             VListItem,
        //             {
        //               attrs: this.$attrs,
        //               class: {
        //                 "black--text": !hover,
        //                 "white--text secondary elevation-12": hover,
        //               },
        //               props: {
        //                 activeClass: "",
        //                 dark: hover,
        //                 link: true,
        //                 ...this.$attrs,
        //               },
        //             },
        //             this.$slots.default
        //           );
        //         },
        //       },
        //     });
        //   },
        //},
    },

    props: {
        value: {
            type: Boolean,
            default: false
        }
    },

    data: () => ({
        userName: localStorage.fullname,
        rtl: localStorage.rtl === "true"
    }),

    watch: {},
    computed: {
        ...mapState(["drawer"])
    },

    methods: {
        ...mapMutations({
            setDrawer: "SET_DRAWER"
        })
    }
};
</script>
