import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import 'material-design-icons-iconfont/dist/material-design-icons.css'
//import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'md',
  },
});

/* export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.purple.darken1,
        secondary: colors.pink.darken3,
        accent: colors.shades.black,
        error: colors.red.accent3,
        background: colors.black
      },
      dark: {
        primary: colors.purple.darken2,
        secondary: colors.pink.darken3,
        accent: colors.shades.black,
        error: colors.red.accent3,
      },
    },
  },
}) */
