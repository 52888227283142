import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import i18n from "../i18n";

Vue.use(VueRouter);

const routes = [
    {
        path: "/auth",
        component: () => import("@/views/Index"),
        children: [
            {
                name: "Login",
                path: "",
                component: () => import("@/views/Account/Login")
            }
        ]
    },
    {
        path: "/",
        name: "Main",
        component: Home,
        beforeEnter: (to, from, next) => {
            if (
                localStorage.getItem("token") &&
                localStorage.getItem("token") !== "null" &&
                localStorage.getItem("token") !== null &&
                localStorage.getItem("token") !== undefined &&
                localStorage.getItem("token") !== "undefined" &&
                localStorage.getItem("token").substr(0, 2) === "ey"
            ) {
                next();
            } else {
                next({
                    name: "Login"
                });
            }
        },
        children: [
            {
                path: "/",
                name: "Dashboard",
                component: () => import("../views/Dashboard")
            },
            {
                path: "/settings",
                name: "settings.settings",
                component: () => import("../views/Settings/Settings.vue")
            },
            {
                path: "/systemlog",
                name: "systemLog.systemLog",
                component: () => import("../views/SystemLog/SystemLog.vue")
            },
            {
                path: "/users",
                name: "users.users",
                component: () => import("../views/Account/Users.vue")
            },
            {
                path: "/branch",
                name: "branches.branches",
                component: () => import("../views/Account/Branch.vue")
            },

            {
                path: "/groups",
                name: "groups.groups",
                component: () => import("../views/Account/Groups.vue")
            },

            {
                path: "/accountTree",
                name: "accountTree.accountTree",
                component: () => import("../views/Account/AccountTree.vue")
            },
            {
                path: "/changePassword",
                name: "users.changePassword",
                component: () => import("../views/Account/ChangePassword.vue")
            },
            {
                path: "/currencies",
                name: "currency.currencies",
                component: () => import("../views/Currency/Currencies.vue")
            },
            {
                path: "/customers",
                name: "customer.customers",
                component: () => import("../views/Customer/Customers.vue")
            },
            {
                path: "/schools/semesters",
                name: "school.semesters",
                component: () => import("../views/Semester/Semesters.vue")
            },
            {
                path: "/schools/schoolstages",
                name: "school.schoolStages",
                component: () => import("../views/SchoolStage/SchoolStages.vue")
            },
            {
                path: "/schools/classes",
                name: "school.classes",
                component: () => import("../views/SchoolClass/Classes.vue")
            },
            {
                path: "/schools/students",
                name: "school.students",
                component: () => import("../views/Student/Students.vue")
            },
            {
                path: "/stocks",
                name: "stock.stocks",
                component: () => import("../views/Stock/Stocks.vue")
            },
            {
                path: "/items",
                name: "item.items",
                component: () => import("../views/Item/Items.vue")
            },
            {
                path: "/vouchers",
                name: "voucher.vouchers",
                component: () => import("../views/Voucher/Vouchers.vue")
            },
            {
                path: "/receiptvoucher/:vType/:id?/",
                name: "voucher.receipt",
                component: () => import("../views/Voucher/ModifyVoucher.vue"),
                props: true
            },
            {
                path: "/paymentvoucher/:vType/:id?/",
                name: "voucher.payment",
                component: () => import("../views/Voucher/ModifyVoucher.vue"),
                props: true
            },
            {
                path: "/entryvoucher/:vType/:id?/",
                name: "voucher.entry",
                component: () => import("../views/Voucher/ModifyVoucher.vue"),
                props: true
            },
            {
                path: "/bills",
                name: "bill.bills",
                component: () => import("../views/Bill/Bills.vue")
            },
            {
                path: "/payments",
                name: "payments.payments",
                component: () => import("../views/Payments/Payments.vue")
            },
            {
                path: "/purchaseBill/:id?",
                name: "bill.purchaseBill",
                component: () => import("../views/Bill/Bill.vue"),
                props: { bType: "Purchase" }
            },
            {
                path: "/saleBill/:id?",
                name: "bill.saleBill",
                component: () => import("../views/Bill/Bill.vue"),
                props: { bType: "Sale" }
            },
            {
                path: "/returnSaleBill/:id?",
                name: "bill.saleReturnBill",
                component: () => import("../views/Bill/Bill.vue"),
                props: { bType: "SaleReturn" }
            },
            {
                path: "/returnPurchaseBill/:id?",
                name: "bill.purchaseReturnBill",
                component: () => import("../views/Bill/Bill.vue"),
                props: { bType: "PurchaseReturn" }
            },
            {
                path: "/installmentsSaleBill/:id?",
                name: "bill.installmentsSaleBill",
                component: () =>
                    import("../views/Bill/InstallmentsSaleBill.vue"),
                props: { bType: "InstallmentsSale" }
            },
            {
                path: "/reports/billsReport",
                name: "reports.billsReport",
                component: () => import("../views/Reports/BillsReport.vue")
            },
            {
                path: "/reports/billsProfitReport",
                name: "reports.billsProfitReport",
                component: () =>
                    import("../views/Reports/BillsProfitReport.vue")
            },
            {
                path: "/reports/itemsProfitReport",
                name: "reports.itemsProfitReport",
                component: () =>
                    import("../views/Reports/ItemsProfitReport.vue")
            },
            {
                path: "/reports/itemsReport",
                name: "reports.itemsReport",
                component: () => import("../views/Reports/ItemsReport.vue")
            },
            {
                path: "/reports/itemsSalesReport",
                name: "reports.itemsSalesReport",
                component: () => import("../views/Reports/ItemsSalesReport.vue")
            },
            {
                path: "/reports/accountsMovementReport",
                name: "reports.accountsMovementReport",
                component: () =>
                    import("../views/Reports/AccountsMovementReport.vue")
            },
            {
                path: "/reports/accountsBalancesReport",
                name: "reports.accountsBalancesReport",
                component: () =>
                    import("../views/Reports/AccountsBalancesReport.vue")
            },
            {
                path: "/reports/customerAccountStatementReport",
                name: "reports.customerAccountStatementReport",
                component: () =>
                    import(
                        "../views/Reports/CustomerAccountStatementReport.vue"
                    )
            },
            {
                path: "/reports/paymentsReport",
                name: "reports.paymentsReport",
                component: () => import("../views/Reports/PaymentsReport.vue")
            },
            {
                path: "/reports/latePaymentsReport",
                name: "reports.latePaymentsReport",
                component: () => import("../views/Reports/LatePaymentsReport.vue")
            },
            {
                path: "/reports/studentsReport",
                name: "reports.studentsReport",
                component: () => import("../views/Reports/StudentsReport.vue")
            },
            {
                path: "/PaymentPlans",
                name: "itemTemplate.itemTemplate",
                component: () => import("../views/Plans/Plans.vue")
            },
            {
                path: "/StudentUpgrading",
                name: "school.studentUpgrading",
                component: () => import("../views/Student/StudentUpgrading.vue")
            },
            {
                path: "/fees",
                name: "fees.fees",
                component: () => import("../views/Fees/Fees.vue")
            },
            {
                path: "/fiscalYears",
                name: "fiscalYear.fiscalYears",
                component: () => import("../views/FiscalYear/FiscalYears.vue")
            },
            {
                path: "/PurchaseNew/:id?",
                name: "bill.purchaseBill",
                component: () =>
                    import("../views/Bill/NewBill/Bill.vue"),
                props: { billType: "Purchase" }
            },
            {
                path: "/InstallmentsSaleNew/:id?",
                name: "bill.installmentsSaleBill",
                component: () =>
                    import("../views/Bill/NewBill/Bill.vue"),
                props: { billType: "InstallmentsSale" }
            },
            {
                path: "/notauthorize",
                name: "notauthorize",
                component: () => import("../views/NotAuthorized.vue")
            }
        ]
    },

    {
        path: "*",
        component: () => import("@/views/Home"),
        children: [
            {
                name: "404 Error",
                path: "",
                component: () => import("@/views/Error")
            }
        ]
    }
];

const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes
});

// const PAGE_TITLE = {
//   Dashboard: "Your Dashboard",
//   About: "About Us Page",
//   Branch: "Branches Page",
//   Users: "Users Page",
//   Doctor: "Doctors Page",
//   Services: "Service Page",
//   "Service Charge": "Service Charge",
//   "Patient Registration": "Patient Registration",
//   "Patient Registration Edit": "Patient Registration Edit",
//   Patients: "Patients",
//   Appointments: "Appointments",
//   Login: "Log in",
// };

router.afterEach(toRoute => {
    window.document.title = i18n.t(toRoute.name);
});

export default router;
